import React from 'react';
import logo from './logo.svg';
import './App.css';

import LandingPage from './pages/landingPage/Index';
import Header from './components/header/Index';

function App() {
	return (
		<>
			<Header />
			<LandingPage />
		</>
	);
}

export default App;
